import { CourseCatalogComponent } from 'src/app/modules/admin/learning-materials/course-catalogs/course-catalogs.component';
import * as env from 'src/environments/environment';

export const environment = {
    production: env.PRODUCTION,
    CODE_APP: 'ONLUYEN',
    BASE_API_URL: env.BASE_API_URL,
    AUTH_SERVER: env.BASE_AUTH_SERVER,
    BASE_API_URL_V1: env.BASE_API_URL_V1,
};

export const CONSTANTS = {
    API_HEALTH_CHECKS: {
        CHECK: 'health/check',
    },
    UPLOAD_FILE_S3: {
        UPLOAD: 'upload/course/signedUrl',
    },
    ACCOUNT: {
        INFO: 'info',
        CHANGE_PASSWORD: 'change/password',
        LOGIN: 'login',
        UPDATE_PROFILE: 'profile/update',
        REFRESH_TOKEN: 'refresh-token',
        REGISTER_ACCOUNT: 'register-account',
        RESET_PASSWORD: 'reset-password',
        AVATAR_PROFILE: 'profile/avatar',
        SIGN_UP: 'account/register-user-custom',
    },
    CERTIFICATE: {
        CREATE: 'certificate-new/create',
        INFO: 'certificate-new/info/',
        UPDATE: 'certificate-new/update/',
    },
    CHAPTER_ADMIN: {
        CREATE: 'admin/chapter/create',
        DELETE: 'admin/chapter/delete/',
        UPDATE: 'admin/chapter/edit/',
        INFO: 'admin/chapter/info/',
        LIST: 'admin/chapter/list',
        CHAPTER_INDEX: 'admin/chapter/update-chapterIndex',
    },
    CHAPTER_NEW: {
        CREATE: 'chapter-new/create',
        DELETE: 'chapter-new/delete/',
        UPDATE: 'chapter-new/edit/',
        INFO: 'chapter-new/info/',
        LIST: 'chapter-new/list',
        CHAPTER_INDEX: 'chapter-new/update-chapterIndex',
    },
    COURSE_DOCUMENT: {
        CREATE: 'course-document/create',
        DELETE: 'course-document/delete/',
        UPDATE: 'course-document/edit/',
        INFO: 'course-document/info/',
        LIST: 'course-document/list',
    },
    COURSE_DOCUMENT_ADMIN: {
        CREATE: 'admin/course-document/create',
        DELETE: 'admin/course-document/delete/',
        UPDATE: 'admin/course-document/edit/',
        INFO: 'admin/course-document/info/',
        LIST: 'admin/course-document/list',
    },
    COURSE_NEW: {
        COURSE_LOG_DETAIL: 'course/course-log-detail/',
        CREATE: 'course/create',
        DELETE: 'course/delete/',
        DELETE_STAFF: '/delete-staff',
        UPDATE: 'course/edit/',
        INFO: 'course/info/',
        EXPORT_COURSE: 'course/export-course',
        EXPORT_DEPARMENT: 'course/export-deparment',
        LIST: 'course/list',
        LIST_OF_STAFF: 'course/list-of-staff',
        LIST_COURSE_OF_STAFF: 'course/list-course-of-staff/',
        LIST_FILE_SUBMIT: 'course/list-file-submit/',
        LIST_STAFF: 'course/list-staff/',
        MARK_POINT_FOR_FILE: 'course/mark-point-for-file',
        ASSIGN_STAFF: 'course/assign-staff',
        SUBMIT_FILE: 'course/submit-file',
        SUBMIT_VIDEO_PDF: 'course/submit-video-pdf',
    },
    COUSER_TEMPLATE: {
        CLONE: 'admin/course/copy-course',
        CREATE: 'admin/course/create',
        DELETE: 'admin/course/delete/',
        UPDATE: 'admin/course/edit/',
        INFO: 'admin/course/info/',
        LIST: 'admin/course/list',
    },
    CURRICULUM_ADMIN: {
        CREATE: 'admin/curriculum/create',
        DELETE: 'admin/curriculum/delete/',
        UPDATE: 'admin/curriculum/edit/',
        FIND: 'admin/curriculum/find',
        INFO: 'admin/curriculum/info/',
        LIST: 'admin/curriculum/list',
    },
    CURRICULUM_NEW: {
        CREATE: 'curriculum-new/create',
        DELETE: 'curriculum-new/delete/',
        UPDATE: 'curriculum-new/edit/',
        INFO: 'curriculum-new/info/',
        LIST: 'curriculum-new/list',
    },
    DEPARTMENT: {
        CREATE: 'department/create',
        DELETE: 'department/delete/',
        UPDATE: 'department/edit/',
        INFO: 'department/info/',
        LIST: 'department/list',
    },
    LESSON_ADMIN: {
        CREATE: 'admin/lesson/create',
        DELETE: 'admin/lesson/delete/',
        UPDATE: 'admin/lesson/edit/',
        INFO: 'admin/lesson/info/',
        LIST: 'admin/lesson/list',
        LESSON_INDEX: 'admin/lesson/update-lessonIndex',
    },
    LESSON_NEW: {
        CREATE: 'lesson-new/create',
        DELETE: 'lesson-new/delete/',
        UPDATE: 'lesson-new/edit/',
        INFO: 'lesson-new/info/',
        LIST: 'lesson-new/list',
        LIST_SUBMIT: 'lesson-new/get-user-list-submit-file/',
        LESSON_INDEX: 'lesson-new/update-lessonIndex',
    },
    ORGANIZATION: {
        CREATE: 'organization/create',
        DELETE: 'organization/delete/',
        UPDATE: 'organization/edit/',
        INFO: 'organization/info/',
        LIST: 'organization/list',
    },
    POSITION: {
        CREATE: 'position/create',
        DELETE: 'position/delete/',
        UPDATE: 'position/edit/',
        INFO: 'position/info/',
        LIST: 'position/list',
    },
    LEVEL_QUESTION: {
        LIST: 'level-question/get-all-level-question',
    },

    QUESTION_BANK: {
        CREATE: 'question-bank/question/create',
        EDIT: 'question-bank/edit/',
    },

    QUESTION: {
        CREATE: 'question-new/create',
        DELETE: 'question-new/delete/',
        UPDATE: 'question-new/edit',
        LIST_QUESTION: 'question-new/list-question',
    },
    QUESTION_ADMIN: {
        CREATE: 'admin/question/create',
        DELETE: 'admin/question/delete/',
        UPDATE: 'admin/question/edit',
        LIST_QUESTION: 'admin/question/list-question',
    },
    SETTING_ADMIN: {
        CREATE: 'admin/setting/create',
        UPDATE: 'admin/setting/edit/',
        INFO: 'admin/setting/info',
    },
    CONFIG_REGISTER: {
        LIST: 'config/register/list',
        DELETE: 'config/register/delete',
        CREATE: 'config/register/create',
        EDIT: 'config/register/edit',
        STATUS: 'config/register/active',
        UPDATE_STATUS_REGISTER: 'config/register/active-field',
        INFO_UNAUTH: 'config/register/info-unauth',
    },
    CONFIG_MENU: {
        LIST: 'config/menu/list',
        CREATE: 'config/menu/create',
        ACTIVE: 'config/menu/active',
        EDIT: 'config/menu/edit',
        DELETE: 'config/menu/delete',
        GET_SITE_ID: 'sites/search',
    },
    CONFIG_LANDING: {
        GET: 'config/landing/list',
        EDIT: 'config/landing/edit',
    },
    UPLOAD_FILE: 'UploadMinIO',
    CONFIG_THEME: {
        LIST: 'config/theme/get-list-theme',
        ACTIVE: 'config/theme/active-theme',
    },
    TEST_ADMIN: {
        DETAIL: 'admin/test/detail-test/',
        DELETE: 'admin/test/remove-question/',
        UPDATE: 'admin/test/update-question/',
        UPDATE_INDEX: 'admin/test/update-question-index/',
    },
    TEST_NEW: {
        DETAIL: 'test-new/detail-test/',
        INFO: 'test-new/staff/info/',
        DONE: 'test-new/detail/done/',
        DONE_TEST: 'test-new/detail/done/',
        QUESTION: 'test-new/question/detail/',
        SUBMIT: 'test-new/submit',
        START: 'test-new/staff/start/',
        DELETE: 'test-new/remove-question/',
        UPDATE: 'test-new/update-question/',
        UPDATE_INDEX: 'test-new/update-question-index/',
    },
    USER: {
        CREATE: 'user/create',
        DELETE: 'user/delete',
        UPDATE: 'user/edit/',
        LIST_USER: 'user/list-user',
        LIST: 'user/list',
        ONLINE_LOG: 'user/online-log',
        STATISTIC: 'user/statistic/',
        INFO: 'user/info/',
        IMPORT_USER: 'user/importusser',
        EXPORT_USER: 'user/export-user',
        UPDATE_PROFILE_STAFF: 'user/update-profile-staff',
    },
    USER_ADMIN: {
        CREATE: 'admin/user/create',
        DELETE: 'admin/user/delete/',
        UPDATE: 'admin/user/edit/',
        LIST: 'admin/user/list',
        INFO: 'admin/user/info/',
        IMPORT_USER: 'admin/user/importusser',
    },
    VIMEO: {
        ADD_VIDEO_TO_FOLDER: 'video/add-video-to-folder',
        CREATE_FOLDER: 'video/create-folder',
        DELETE_FOLDER: 'video/delete-folder',
        DELETE_VIDEO: 'video/delete-video/',
        UPDATE_FOLDER: 'video/update-folder',
        REMOVE_VIDEO_TO_FOLDER: 'video/remove-video-to-folder',
        UPLOAD_VIDEO: 'video/upload-video',
    },
    USER_MANAGEMENT: {
        USER_GROUPS: {
            LIST: 'group-user/list',
            DELETE: 'group-user/delete/',
            CREATE: 'group-user/create',
            DETAIL: 'group-user/group-with-list-user',
            UPDATE: 'group-user/edit/',
        },
        SITE: {
            LIST: 'sites/search',
            CREATE: 'sites/create',
            EDIT: 'sites/edit/',
            DELETE: 'sites/delete/',
            SEARCH: '',
            LIST_MANAGER: 'manager-level/level-1',
            EXPORT: 'sites/export-excel',
            IMPORT: 'sites/import-excel',
            SITE_BY_ID: 'sites/info/',
            UPDATE_LOGIN: 'sites/update-setting-login',
            UPDATE_CSKH: 'sites/update-setting-cskh',
            UPDATE_CONTACT: 'sites/update-setting-contact',
            UPDATE_SECURITY: 'sites/update-setting-sercurity',
            UN_AUTH: 'sites/info-unauth/',
            SITE_BY_DOMAIN: 'sites/info/',
        },

        POSITIONS: {
            LIST: 'position/list',
            DELETE: 'position/delete/',
            CREATE: 'position/create',
            UPDATE: 'position/edit/',
            DETAIL: 'position/info/',
            IMPORT: 'position/upload',
        },
        AUTHORIZATION: {
            GET_LIST_FEATURE: 'Authorize/get-list-feature',
            GET_LIST_POLICY: 'Policy/get-list',
            CREATE_POLICY: 'Policy/create',
            UPDATE_POLICY: 'Policy/update',
            LIST_SITE: 'sites/get-all-sites',
            GET_POLICY_DETAIL: 'Policy/detail/',
            DELETE_POLICY_DETAIL: 'Policy/delete/',
            GET_USER_BY_GROUP: 'Policy/list-user/',
            ADD_USER: 'Policy/add-user',
            DELETE_USER: 'Policy/delete-user',
            DETAIL_USER: 'Policy/detail-user/',
            UPDATE_USER: 'Policy/update-user',
            UPDATE_ROLE: 'Authorize/update-role/',
            DELETE_ROLE: 'Authorize/delete-role/',
        },
    },
    POSITIONS: {
        LIST: 'position/list',
        DELETE: 'position/delete/',
        CREATE: 'position/create',
        UPDATE: 'position/edit/',
        DETAIL: 'position/info/',
        IMPORT: 'position/upload',
    },
    AUTHORIZATION: {
        GET_LIST_FEATURE: 'Authorize/get-list-feature',
        GET_LIST_POLICY: 'Policy/get-list',
        CREATE_POLICY: 'Policy/create',
        UPDATE_POLICY: 'Policy/update',
        LIST_SITE: 'sites/get-all-sites',
        GET_POLICY_DETAIL: 'Policy/detail/',
        DELETE_POLICY_DETAIL: 'Policy/delete/',
        GET_USER_BY_GROUP: 'Policy/list-user/',
        ADD_USER: 'Policy/add-user',
        DELETE_USER: 'Policy/delete-user',
        DETAIL_USER: 'Policy/detail-user/',
        UPDATE_USER: 'Policy/update-user',
        UPDATE_ROLE: 'Authorize/update-role/',
        DELETE_ROLE: 'Authorize/delete-role/',
    },

    WEB_OPERATIONS: {
        NEWS: {
            LIST: 'news/list',
            CREATE: 'news/create',
            EDIT: 'news/edit',
            DELETE: 'news/delete',
            IMPORT: 'news/import-news',
            EXPORT: 'news/export-news',
        },
        NEWS_CATEGORY: {
            LIST: 'category/list',
            CREATE: 'category/create',
            EDIT: 'category/edit',
            DELETE: 'category/delete',
            IMPORT: 'category/import-category',
            EXPORT: 'category/export-category',
        },
        NOTIFICATIONS: {
            LIST: 'notifications/list',
            DELETE: 'notifications/delete',
            CREATE: 'notifications/create',
            EDIT: 'notifications/edit',
            INFO: 'notifications/info/',
        },
        TEMPLATES: {
            LIST: 'template/list',
            EDIT: 'template/edit',
            CHECK: 'template/check-template',
            INFO: 'template/info/',
        },
    },
    LEARNING_MATERIALS: {
        COURSES: {
            LIST: 'course/get-list-course',
            DETAIL: 'course/detail/',
            UN_AUTH: 'course/un-auth/',
            CREATE: 'course/create',
            UPDATE: 'course/edit/',
            UPDATE_STATUS: 'course/update-status/',
            DELETE: 'course/delete/',
            CHAPTER: {
                CREATE: 'chapter-new/create',
                DELETE: 'chapter-new/delete/',
                UPDATE: 'chapter-new/edit/',
            },
            LESSON: {},
        },
        TEACHERS: {
            LIST: 'teacher/list',
            EDIT: 'teacher/edit',
            CREATE: 'teacher/create',
            DELETE: 'teacher/delete',
            INFO: 'teacher/info/',
        },
        COURSE_CATALOGS: {
            LIST: 'course-catalog/search',
            DELETE: 'course-catalog/delete/',
            CREATE: 'course-catalog/create',
            EDIT: 'course-catalog/edit/',
        },
        TOPIC_LIST: {
            LIST: 'exam-syllabus/get-all-exam-syllabus',
            CREATE: 'exam-syllabus/create',
            SEARCH: 'exam-syllabus/search',
            DELETE: 'exam-syllabus/delete/',
            EDIT: 'exam-syllabus/edit/',
        },
        LEVEL_QUESTIONS: {
            LIST: 'level-question/get-all-level-question',
            DELETE: 'level-question/delete/',
            CREATE: 'level-question/create',
            EDIT: 'level-question/edit/',
            INFO: 'level-question/info/',
        },
    },

    CLOUDFLARE: {
        UPLOAD: 'cloudflare/uploadurl',
        DETAIL: 'cloudflare/detail-video/',
        DELETE: 'cloudflare/delete-video/',
    },
    MANAGER_LEVEL: {
        ORGANIZATINAL: 'manager-level/organizational-chart',
        LIST_USER: 'user/list-user',
        CREATE: 'manager-level',
        DELETE: 'manager-level/delete/',
        DETAIL: 'manager-level/detail/',
        UPDATE: 'manager-level/update/',
        LIST_1: 'manager-level/level-1',
        LIST_2: 'manager-level/level-2',
        LIST_3: 'manager-level/level-3',
        LEVEL1: 'manager-level/level-1',
        LEVEL2: 'manager-level/level-2',
        LEVEL3: 'manager-level/level-3',
    },
    TRAINING_MANAGEMENT: {
        TEACHER: {
            LIST: 'teacher/list',
        },
    },
    CERTIFICATES: {
        CERTIFICATE: {
            LIST: 'certificate/list',
            INFO: 'certificate/info/',
        },
        MCERTIFICATE: {
            LIST: 'mcertificate/list',
            INFO: 'mcertificate/info/',
        },
    },
};
